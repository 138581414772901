export const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 403) {
      return error.response
    }
    if (error.response.status === 401) {
      setTimeout(() => {
        localStorage.clear()
        window.location.replace('/')
      }, 1000);
    }
    return error.response.data.error ? error.response.data.error : error.response.data.message;
  } else if (error.request) {
    return error.message;
  } else {
    return 'Something went wrong';
  }
};
