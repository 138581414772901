const AuthRoutes = {
  login: 'login',
  logout: 'logout',
  sendVerificationCode: 'sendVerificationCode',
  verifyOtp: 'verifyVerificationCode',
  changePassword: 'changePassword',
  changeEmail: 'changeEmail',
  changeContact: 'changeContact',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  getNotifications: 'getNotifications',
};

export default AuthRoutes