const Colors = {
    primary: '#234386',
    primaryGradient: 'linear-gradient(to right, #294787, #213d7a, #19326e, #0f2961, #051f55)',
    secondary: '#ba2b2b',
    secondaryGradient: 'linear-gradient(to right, #bb3131, #b82b2b, #b52525, #b21f1f, #af1818)',
    tertiary: '#1b1b29',
    tertiaryGradient: 'linear-gradient(to right, #212835, #2c333f, #373d49, #424953, #4e545e)',
    aqua: '#27DEBF',
    textPrimary: '#001737',
    textSecondary: '#939393',
    charcoalBlack: '#414141',
    iron: '#d9d9d9',
    charcoalGrey: '#323B4B',
    grey: '#7e7e7e',
    gunMetal: '#263238',
    cyanBlue: '#F6F9FC',
    white: '#ffffff',
    black: '#222222',
    danger: '#FF2121',
    success: '#79AA5A',
    pending: '#0185BA',
    shipped: '#BA68C8',
    released: '#FF8B00',


    titanWhite: '#eff0f6',
    desertStorm: '#f7f7f7',
    ghostWhite: '#F5F8FC',
    snowDrift: '#F9F9F9',
    flashWhite: '#EFF0F6',
    mercury: '#DFE5EB',
    smokyBlack: '#0707074d',
    textLight: '#484D50',
    yellowishOrange: '#FF8B004D',
    ebonyClay: '#252340',
    cloud: '#C4C4C4',
};

export default Colors;