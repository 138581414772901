import React, { lazy } from 'react';

const Login = lazy(() => import('views/auth/Login'));
const ForgotPassword = lazy(() => import('views/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('views/auth/ResetPassword'));

const AuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
]

export default AuthRoutes
