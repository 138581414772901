import React, { Fragment, Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { CircleLoading } from 'components/loaders/Loaders';

function DashboardLayout() {

  return (
    <Fragment>

      {/* ========== Header ========== */}
      <Header />

      <Box sx={{ display: 'flex' }}>

        {/* ========== Sidebar ========== */}
        <Sidebar />

        <Box component="main" sx={{ height: 'calc(100vh - 104px)', width: 1, position: 'relative', overflowY: 'auto', py: 2.5, px: 2 }}>
          <Suspense fallback={<CircleLoading />}>
            <Outlet />
          </Suspense>
        </Box>

      </Box>

    </Fragment>
  )
}

export default DashboardLayout