import React, { Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';
import { CircleLoading } from 'components/loaders/Loaders';

function WebLayout() {
  return (
    <Box component="main">
      <Suspense fallback={<CircleLoading />}>
        <Outlet />
      </Suspense>
    </Box>
  )
}

export default WebLayout