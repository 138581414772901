import React, { lazy } from "react";

const Dashboard = lazy(() => import('views/dashboard'));
const Tags = lazy(() => import('views/product-management/tags'));
const Types = lazy(() => import('views/product-management/types'));
const Variations = lazy(() => import('views/product-management/variations'));
const Categories = lazy(() => import('views/product-management/categories'));
const Subcategories = lazy(() => import('views/product-management/subcategories'));
const BrandManagement = lazy(() => import('views/brand-management'));
const Campaign = lazy(() => import('views/sale-management/campaign'));
const CreateCampaign = lazy(() => import('views/sale-management/campaign/CreateCampaign'));
const UpdateCampaign = lazy(() => import('views/sale-management/campaign/UpdateCampaign'));
const FlashSale = lazy(() => import('views/sale-management/flash-sale'));
const CreateFlashSale = lazy(() => import('views/sale-management/flash-sale/CreateFlashSale'));
const UpdateFlashSale = lazy(() => import('views/sale-management/flash-sale/UpdateFlashSale'));
const SaleProducts = lazy(() => import('views/sale-management/flash-sale/SaleProducts'));
const Profile = lazy(() => import('views/profile'));
const Commission = lazy(() => import('views/commission'));
const Vat = lazy(() => import('views/tax-management/vat'));
const TaxTypes = lazy(() => import('views/tax-management/TaxTypes'));
const Orders = lazy(() => import('views/orders'));
const OrderDetail = lazy(() => import('views/orders/Detail'));
const FulfillmentOrders = lazy(() => import('views/orders/FulfillmentOrders'));
const FulfillmentOrderDetail = lazy(() => import('views/orders/FulfillmentOrderDetail'));
const Products = lazy(() => import('views/product-management/products'));
const ProductDetail = lazy(() => import('views/product-management/products/ProductDetail'));
const AdminSellerTransactions = lazy(() => import('views/payment-management/admin-seller-transactions'));
const BuyerAdminTransactions = lazy(() => import('views/payment-management/buyer-admin-transactions'));
const PaymentRequest = lazy(() => import('views/payment-management/payment-requests'));
const Receivables = lazy(() => import('views/payment-management/receivables'));
const Sales = lazy(() => import('views/sales'));
const Revenues = lazy(() => import('views/revenues'));
const Vouchers = lazy(() => import('views/vouchers'));
const CreateUser = lazy(() => import('views/user-management/admin-users/CreateUser'));
const UpdateUser = lazy(() => import('views/user-management/admin-users/UpdateUser'));
const AdminUsers = lazy(() => import('views/user-management/admin-users'));
const Manufacturers = lazy(() => import('views/user-management/manufactures'));
const Dropshippers = lazy(() => import('views/user-management/dropshippers'));
const Suppliers = lazy(() => import('views/user-management/suppliers'));
const GuestBuyers = lazy(() => import('views/user-management/guest-buyers'));
const GuestBuyerDetail = lazy(() => import('views/user-management/guest-buyers/guest-buyer-detail'));
const UserDetail = lazy(() => import('views/user-management/detail'));
const UserMembership = lazy(() => import('views/user-management/user-membership'));
const EmailMarketing = lazy(() => import('views/email-marketing/index'));
const SendEmail = lazy(() => import('views/email-marketing/SendEmail'));
const FeaturedProducts = lazy(() => import('views/product-management/featured-product'));
const ProductApprovals = lazy(() => import('views/product-management/product-approval'));
const BannedProducts = lazy(() => import('views/product-management/banned-product'));
const Chat = lazy(() => import('views/chat'));
const Fulfillment = lazy(() => import('views/fulfillment'));
const FulfillmentProductDetail = lazy(() => import('views/fulfillment/ProductDetail'));
const InvoiceManagement = lazy(() => import('views/invoice-management'));
const CreateInvoice = lazy(() => import('views/invoice-management/CreateInvoice'));
const UpdateInvoice = lazy(() => import('views/invoice-management/UpdateInvoice'));
const ViewInvoice = lazy(() => import('views/invoice-management/ViewInvoice'));
const WebsiteManagement = lazy(() => import('views/website-management'));
const CreateWebSection = lazy(() => import('views/website-management/CreateWebSection'));
const ViewWebSection = lazy(() => import('views/website-management/ViewWebSection'));
const CourierManagement = lazy(() => import('views/courier-management'));
const OrderHistory = lazy(() => import('views/courier-management/OrderHistory'));
const PaymentHistory = lazy(() => import('views/courier-management/PaymentHistory'));
const Refund = lazy(() => import('views/refunds'));

const DashboardRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/product-management/tags",
    component: <Tags />,
  },
  {
    path: "/product-management/types",
    component: <Types />,
  },
  {
    path: "/product-management/variations",
    component: <Variations />,
  },
  {
    path: "/product-management/categories",
    component: <Categories />,
  },
  {
    path: "/product-management/subcategories",
    component: <Subcategories />,
  },
  {
    path: "/brand-management",
    component: <BrandManagement />,
  },
  {
    path: "/sale-management/campaigns",
    component: <Campaign />,
  },
  {
    path: "/sale-management/campaigns/create-campaign",
    component: <CreateCampaign />,
  },
  {
    path: "/sale-management/campaigns/edit-campaign",
    component: <UpdateCampaign />,
  },
  {
    path: "/sale-management/flash-sales",
    component: <FlashSale />,
  },
  {
    path: "/sale-management/flash-sales/create-flash-sale",
    component: <CreateFlashSale />,
  },
  {
    path: "/sale-management/flash-sales/edit-flash-sale",
    component: <UpdateFlashSale />,
  },
  {
    path: "/sale-management/flash-sales/flash-sale-products",
    component: <SaleProducts />,
  },
  {
    path: "/order-management/orders",
    component: <Orders />,
  },
  {
    path: "/order-management/order-detail/:id",
    component: <OrderDetail />,
  },
  {
    path: "/order-management/fulfillment-orders",
    component: <FulfillmentOrders />,
  },
  {
    path: "/order-management/fulfillment-orders/detail/:id",
    component: <FulfillmentOrderDetail />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/commission",
    component: <Commission />,
  },
  {
    path: "/tax-management/vat",
    component: <Vat />,
  },
  {
    path: "/tax-management/tax-types",
    component: <TaxTypes />,
  },
  {
    path: "/product-management/products",
    component: <Products />,
  },
  {
    path: "/product-management/product-detail/:id",
    component: <ProductDetail />,
  },
  {
    path: "/payment-management/admin-seller-payments",
    component: <AdminSellerTransactions />,
  },
  {
    path: "/payment-management/buyer-admin-payments",
    component: <BuyerAdminTransactions />,
  },
  {
    path: "/payment-management/payment-requests",
    component: <PaymentRequest />,
  },
  {
    path: "/payment-management/receivables",
    component: <Receivables />,
  },
  {
    path: "/sales",
    component: <Sales />,
  },
  {
    path: "/revenues",
    component: <Revenues />,
  },
  {
    path: "/vouchers",
    component: <Vouchers />,
  },
  {
    path: "/user-management/users/create-user",
    component: <CreateUser />,
  },
  {
    path: "/user-management/users/edit-user",
    component: <UpdateUser />,
  },
  {
    path: "/user-management/users",
    component: <AdminUsers />,
  },
  {
    path: "/user-management/manufacturers",
    component: <Manufacturers />,
  },
  {
    path: "/user-management/manufacturers/:id",
    component: <UserDetail />,
  },
  {
    path: "/user-management/dropshippers",
    component: <Dropshippers />,
  },
  {
    path: "/user-management/dropshippers/:id",
    component: <UserDetail />,
  },
  {
    path: "/user-management/suppliers",
    component: <Suppliers />,
  },
  {
    path: "/user-management/suppliers/:id",
    component: <UserDetail />,
  },
  {
    path: "/user-management/guest-buyers",
    component: <GuestBuyers />,
  },
  {
    path: "/user-management/guest-buyer/:id",
    component: <GuestBuyerDetail />,
  },
  {
    path: "/user-management/users-membership",
    component: <UserMembership />,
  },
  {
    path: "/email-marketing",
    component: <EmailMarketing />,
  },
  {
    path: "/send-email",
    component: <SendEmail />,
  },
  {
    path: "/product-management/featured-products",
    component: <FeaturedProducts />,
  },
  {
    path: "/product-management/product-approvals",
    component: <ProductApprovals />,
  },
  {
    path: "/product-management/banned-products",
    component: <BannedProducts />,
  },
  {
    path: "/chat",
    component: <Chat />,
  },
  {
    path: "/fulfillment",
    component: <Fulfillment />,
  },
  {
    path: "/fulfillment/product-detail/:id",
    component: <FulfillmentProductDetail />,
  },
  {
    path: "/invoice-management",
    component: <InvoiceManagement />,
  },
  {
    path: "/invoice-management/create-invoice",
    component: <CreateInvoice />,
  },
  {
    path: "/invoice-management/edit-invoice",
    component: <UpdateInvoice />,
  },
  {
    path: "/invoice-management/view-invoice/:id",
    component: <ViewInvoice />,
  },
  {
    path: "/website-management",
    component: <WebsiteManagement />,
  },
  {
    path: "/website-management/create-web-section",
    component: <CreateWebSection />,
  },
  {
    path: "/website-management/view-web-section/:id",
    component: <ViewWebSection />,
  },
  {
    path: "/courier-management",
    component: <CourierManagement />,
  },
  {
    path: "/courier-management/order-history",
    component: <OrderHistory />,
  },
  {
    path: "/courier-management/payment-history",
    component: <PaymentHistory />,
  },
  {
    path: "/refund-return",
    component: <Refund />,
  },
]

export default DashboardRoutes
