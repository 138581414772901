import { get, post, patch } from 'apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  logout: async () => {
    const data = await post(AuthRoutes.logout);
    return data;
  },
  sendVerificationCode: async () => {
    const data = await post(AuthRoutes.sendVerificationCode);
    return data;
  },
  verifyOtp: async (obj) => {
    const data = await post(AuthRoutes.verifyOtp, obj);
    return data;
  },
  changePassword: async (obj) => {
    const data = await patch(AuthRoutes.changePassword, obj);
    return data;
  },
  changeEmail: async (obj) => {
    const data = await patch(AuthRoutes.changeEmail, obj);
    return data;
  },
  changeContact: async (obj) => {
    const data = await patch(AuthRoutes.changeContact, obj);
    return data;
  },
  forgotPassword: async (obj) => {
    const data = await post(AuthRoutes.forgotPassword, obj);
    return data;
  },
  resetPassword: async (obj) => {
    const data = await patch(AuthRoutes.resetPassword, obj);
    return data;
  },
  getNotifications: async (params) => {
    const data = await get(AuthRoutes.getNotifications, params);
    return data;
  },
}

export default AuthServices